import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppProvider, AppState } from "./contexts/AppContext";
import { theme } from "./Theme";
import { Error404 } from "./views/errors/404";
import { Landing } from "./views/landing/Landing";
import { Resume } from "./views/misc/Resume";
import { BlobbyRecursiveDivision } from "./views/projects/BlobbyRecursiveDivision";
import { Projects } from "./views/projects/Projects";
import { ProjectsLanding } from "./views/projects/ProjectsLanding";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCJae-zq5RKIZs-404PtbfSnmdmBb6qLWE",
  authDomain: "alexslaterio.firebaseapp.com",
  databaseURL:
    "https://alexslaterio-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "alexslaterio",
  storageBucket: "alexslaterio.appspot.com",
  messagingSenderId: "924961967878",
  appId: "1:924961967878:web:134ead92c7dfb024e45887",
  measurementId: "G-F0CX8KCL4K",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);

function App() {
  const [appState, updateAppState] = useState<AppState>({});

  return (
    <BrowserRouter>
      <AppProvider value={{ appState, actions: { updateAppState } }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box component="main">
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="projects" element={<Projects />}>
                <Route index element={<ProjectsLanding />} />
                <Route
                  path="recursive-division"
                  element={<BlobbyRecursiveDivision />}
                />
              </Route>
              <Route path={"cv"} element={<Resume />} />
              <Route path={"resume"} element={<Resume />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Box>
        </ThemeProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;

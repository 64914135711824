import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export type SkillData = {
  title: string;
};
export type SkillListProps = {
  skills: SkillData[];
};
export function SkillList({ skills }: SkillListProps) {
  const [skillCards, setSkillCards] = useState<React.ReactElement[]>([]);

  useEffect(() => {
    const newSkillCards = skills.map((skill) => (
      <SkillCard key={skill.title} skill={skill} />
    ));
    setSkillCards(newSkillCards);
  }, skills);

  return (
    <Box>
      <Grid
        container
        spacing={1}
        columns={1}
        direction={"row"}
        sx={{
          overflowX: "scroll",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          p: 2,
        }}
      >
        {skillCards}
      </Grid>
    </Box>
  );
}

export type SkillCardProps = {
  skill: SkillData;
};
export function SkillCard({ skill }: SkillCardProps) {
  return (
    <Grid item height={"100%"}>
      <Card sx={{ boxShadow: 0, border: 1, borderColor: "primary.main" }}>
        <CardContent sx={{ p: 2 }}>
          <Typography mb={-1}>{skill.title}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

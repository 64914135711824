import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LandingHero } from "./components/LandingHero";
import { SkillData, SkillList } from "./components/SkillList";

export const skills: SkillData[] = [
  { title: "AWS Cloud" },
  { title: "React" },
  { title: "Typescript/JS" },
  { title: "Terraform" },
  { title: "Node.JS" },
  { title: "Line Management" },
  { title: "Docker" },
  { title: "CI/CD" },
  { title: "TDD/BDD" },
  { title: "SQL" },
  { title: "C#" },
  { title: "C++" },
  { title: "AngularJS" },
  { title: "Aurelia" },
  { title: "SASS" },
  { title: "Redis" },
  { title: "RethinkDB" },
  { title: "MongoDB" },
  { title: "..." },
];

export type LandingProps = {};
export function Landing({}: LandingProps) {
  return (
    <Box p={4}>
      {/* <LandingNavigation /> */}
      <LandingHero />
      {/* <WhoAmI /> */}
      <SkillList skills={skills} />
      {/* <ProjectList /> */}
    </Box>
  );
}

export function WhoAmI() {
  return (
    <Container maxWidth={"md"}>
      <Box>
        <Typography>
          My name is Alex - I have over six years of professional experience in
          the industry and more in personal projects. The breadth of my
          experience is with Typescript, AWS, React, and other related
          technologies. My main strength has always been my passion and how that
          drives my ability to learn quickly. Software development has always
          been a hobby of mine, and I love what I do; I believe this shows in my
          work.
        </Typography>
      </Box>
    </Container>
  );
}

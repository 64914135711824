import { Email, GitHub, LinkedIn } from "@mui/icons-material";
import { Button } from "@mui/material";

export function ContactButtons() {
  return (
    <>
      <Button
        href="https://github.com/sudo-alexslater"
        target="_blank"
        startIcon={<GitHub />}
      >
        GitHub
      </Button>
      <Button
        href="https://linkedin.com/in/alexander-slater"
        target="_blank"
        startIcon={<LinkedIn />}
      >
        LinkedIn
      </Button>
      <Button href="mailto:alex@alexslater.io" startIcon={<Email />}>
        Email
      </Button>
    </>
  );
}

import styled from "@emotion/styled";
import { DocumentScannerRounded } from "@mui/icons-material";
import { Button, Container, Fade, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useLayoutEffect, useState } from "react";
import { ContactButtons } from "../../../components/ContactButtons";

const CenterHeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;
const LandingBox = styled(Box)`
  // background-image: url("/images/abstract-backsplash.jpg");
  // -webkit-mask-image: -webkit-linear-gradient(
  //   0deg,
  //   rgba(0, 0, 0, 0) 0%,
  //   rgba(0, 0, 0, 1) 25%,
  //   rgba(0, 0, 0, 1) 75%,
  //   rgba(0, 0, 0, 0) 100%
  // );
  // mask-image: linear-gradient(
  //   to bottom,
  //   rgba(0, 0, 0, 0) 0%,
  //   rgba(0, 0, 0, 1) 25%,
  //   rgba(0, 0, 0, 1) 75%,
  //   rgba(0, 0, 0, 0) 100%
  // );
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export function LandingHero() {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  useLayoutEffect(() => {
    setTriggerAnimation(true);
  }, []);
  return (
    <LandingBox>
      <Fade in={triggerAnimation} timeout={triggerAnimation ? 1000 : undefined}>
        <CenterHeroContainer maxWidth={"md"}>
          <Grid container direction={"column"}>
            <Grid item>
              <Box>
                <Typography variant={"h1"}>
                  <Box>
                    <Box sx={{ display: "inline" }}>Alex </Box>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: "text.secondary",
                        display: "inline",
                      }}
                    >
                      Slater
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ display: "inline" }}>Software </Box>
                    <Box
                      sx={{
                        fontWeight: "bold",
                        color: "text.secondary",
                        display: "inline",
                      }}
                    >
                      Engineer
                    </Box>
                  </Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ mt: 2 }}>
                <Button
                  href="/CV.pdf"
                  target="_blank"
                  startIcon={<DocumentScannerRounded />}
                  sx={{ mr: 3 }}
                  variant="contained"
                >
                  Download CV
                </Button>
                <ContactButtons />
              </Box>
            </Grid>
          </Grid>
        </CenterHeroContainer>
      </Fade>
    </LandingBox>
  );
}
